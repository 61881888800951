import React, { memo, useEffect, useState } from 'react';
import { Script, graphql } from 'gatsby';
import { getModelUrl } from './../utils/helpers';
import Seo from '../components/common/Seo';
import GraphQLError from '../components/error';
import Layout from '../layout';
import Single_product from '../components/single_product';
import { getProductImages } from '../services/productVariant';

export const Head = ({ location, params, data, pageContext }) => {
  const { product, fgcProductVideo } = data;

  const seo = {
    title: product.seo.title,
    description: product.seo.description,
    keywords: [],
    url: getModelUrl('buy', product.handle),
    hideScript: true,
    location,
  };

  // if (typeof fbq !== 'undefined' && product && product?.title) {
  //   fbq('track', 'ViewContent', {
  //     content_ids: [product?.variants[0].shopifyId],
  //     content_name: product?.title,
  //     currency: 'AUD',
  //     value: product.variants[0].price,
  //   });
  // }

  return (
    <Seo {...seo}>
      <script type="text/javascript">
        {`window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'view_item',
            ecommerce: {
              items: [${product.variants
                .map(
                  (v) => `{
                item_name: "${product.title}",
                item_id: "${v.sku}",
                price: "${v.price}",
                item_brand: "${product.vendor}",
                ${product.collections
                  .filter((v) => v.title.indexOf('Brands') === -1)
                  .map((v, i) => `item_category${i + 1 > 1 ? i + 1 : ''}: "${v.title}"`)
                  .join()},
                quantity: '1'
              }`
                )
                .join()}]
            }
          });`}
      </script>

      {typeof fbq !== 'undefined' && product && product?.title && (
        <script>
          {`fbq('track', 'ViewContent', {
            content_ids: ['${product?.variants[0].legacyResourceId}'],
            content_name: '${product?.title}',
            currency: 'AUD',
            value: ${product.variants[0].price},
          });`}
        </script>
      )}

      {(fgcProductVideo?.videos || [])?.map((v) => (
        <Script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'VideoObject',
            name: product.title,
            description: v?.alt,
            thumbnailUrl: v?.imageUrl ? [v?.imageUrl] : [],
            uploadDate: product.updatedAt,
            duration: 'PT1M54S',
            embedUrl: v?.embedUrl,
            // interactionStatistic: {
            //   '@type': 'InteractionCounter',
            //   interactionType: { '@type': 'WatchAction' },
            //   userInteractionCount: 5647018,
            // },
            regionsAllowed: 'US,NL',
          })}
        </Script>
      ))}
    </Seo>
  );
};

const SingleProductTemplate = (props) => {
  const { data, errors, location } = props;

  if (errors) return <GraphQLError errors={errors} />;

  const {
    product,
    brand,
    posts,
    images,
    metafields,
    relatedProducts,
    relatedProductsSeller,
    fgcProductVideo,
  } = data;

  const [state, setState] = useState(images);

  const swatch_images = images.swatch_images.filter(
    (v) => v.altText && v.altText.trim().indexOf('swatch_') === 0
  );
  product.videos = fgcProductVideo?.videos || [];

  useEffect(() => {
    getProductImages(product?.shopifyId).then((_images) => {
      const imageIds = state.images.map((v) => v?.shopifyId) || [];
      const __images = [...state.images];
      const __m_images = [...state.m_images];
      const __sm_images = [...state.sm_images];

      _images.map((img, index) => {
        if (!imageIds.includes(img?.id)) {
          __images?.splice(index, 0, img);
          __m_images?.splice(index, 0, img);
          __sm_images?.splice(index, 0, img);

          setState((_) => ({
            ..._,
            images: __images,
            m_images: __m_images,
            sm_images: __sm_images,
          }));
        }
      });
    });

    if (product.variants.length > 1 && swatch_images.length > 0) {
      const objVariants = {},
        variants = [],
        titleVariants = [];
      product.variants.forEach((variant) => {
        const titleVariant =
          variant?.selectedOptions?.filter((v) => v.name == 'Color')?.[0]?.value || variant?.title;
        objVariants[titleVariant] = variant;
      });

      swatch_images.forEach((image) => {
        const titleVariant = image.altText.replace('swatch_', '').trim();

        if (objVariants[titleVariant] && !titleVariants.includes(titleVariant)) {
          variants.push(objVariants[titleVariant]);
          titleVariants.push(titleVariant);
        }
      });

      product.variants = variants.filter(
        (variant) =>
          variant.metafields.findIndex((v) => {
            try {
              return v.key === 'inactive' && (v.value === 'T' || JSON.parse(v.value)?.[0] === 'T');
            } catch (error) {
              return false;
            }
          }) === -1
      );
    }
    product.metafields = metafields.nodes;
  }, []);

  return (
    <Layout textWhite={true} location={location}>
      <Single_product
        product={product}
        images={state}
        brand={brand}
        posts={posts}
        swatch_images={swatch_images}
        relatedProducts={relatedProducts?.nodes || []}
        relatedProductsSeller={relatedProductsSeller?.nodes || []}
      />
    </Layout>
  );
};

export default memo(SingleProductTemplate);

export const query = graphql`
  query SingleProductQuery($id: String!, $name: [String]!, $shopifyId: String!) {
    product: shopifyProduct(id: { eq: $id }) {
      id
      title
      productType
      shopifyId
      storefrontId
      legacyResourceId
      publishedAt
      featuredImage {
        originalSrc
      }
      collections {
        title
      }
      seo {
        description
        title
      }
      hasOutOfStockVariants
      variants {
        id
        legacyResourceId
        title
        sku
        image {
          originalSrc
          gatsbyImageData(formats: WEBP, breakpoints: 20, width: 20)
        }
        selectedOptions {
          name
          value
        }
        shopifyId
        storefrontId
        price
        compareAtPrice
        metafields {
          key
          value
        }
      }
      description
      descriptionHtml
      handle
      tags
      vendor
      updatedAt
    }

    fgcProductVideo(shopifyId: { eq: $shopifyId }) {
      videos {
        embedUrl
        imageAlt
        imageUrl
        alt
        index
      }
    }

    relatedProducts: allShopifyProduct(
      filter: {
        vendor: { in: $name }
        id: { ne: $id }
        priceRangeV2: {
          minVariantPrice: { amount: { ne: "0.0" } }
          maxVariantPrice: { amount: { ne: "0.0" } }
        }
      }
      sort: { fields: createdAt, order: DESC }
      limit: 10
    ) {
      nodes {
        shopifyId
        title
        featuredImage {
          gatsbyImageData(width: 315, formats: WEBP, breakpoints: 315)
          altText
        }
        handle
        vendor
        productType
        tags
        createdAt
        updatedAt
      }
    }

    relatedProductsSeller: allShopifyProduct(
      filter: {
        priceRangeV2: { maxVariantPrice: { amount: { ne: "0.0" } } }
        collections: { elemMatch: { title: { eq: "Best Sellers" } } }
      }
      sort: { fields: sellingPlanGroupCount, order: DESC }
      limit: 10
    ) {
      nodes {
        shopifyId
        title
        featuredImage {
          gatsbyImageData(width: 315, formats: WEBP, breakpoints: 315)
          altText
        }
        handle
        vendor
        productType
        tags
        createdAt
        updatedAt
      }
    }

    metafields: allShopifyProductMetafield(
      filter: {
        productId: { eq: $id }
        key: { in: ["summaryData", "promotion_type", "active_ingredients", "application"] }
      }
    ) {
      nodes {
        key
        value
      }
    }

    images: shopifyProduct(id: { eq: $id }) {
      images {
        shopifyId
        altText
        gatsbyImageData(formats: WEBP, breakpoints: 700, width: 700)
        src
      }

      sm_images: images {
        altText
        gatsbyImageData(formats: WEBP, breakpoints: 120, width: 120)
        src
      }

      m_images: images {
        altText
        gatsbyImageData(formats: WEBP, breakpoints: 420, width: 420)
        src
      }

      swatch_images: images {
        altText
        gatsbyImageData(formats: WEBP, breakpoints: 20, width: 20)
        src
      }
    }

    brand: prismicBrand(data: { name: { in: $name } }) {
      url
      data {
        name
        title_collection
        brand_logo {
          gatsbyImageData(breakpoints: 150, width: 150)
          alt
        }
        parent_brand {
          brand {
            document {
              ... on PrismicBrand {
                data {
                  name
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
