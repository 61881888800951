import { useStore } from '../../hooks';
import React, { memo } from 'react';
import { filterHasFreeGift } from '../../utils/helpers';

const ListFreeGift = ({ product }) => {
  const { rules } = useStore();

  return filterHasFreeGift(rules, product)?.map((rule, i) => {
    const { rule_name, free_gift_subtitle, terms_conditions, product_page_text } = rule;
    //return free_gift_group.map(({ free_gift_product: {title, image} }, i) => {
    return (
      <div key={i} className="free-gift" data-rule={rule_name}>
        <div className="free-gift__sub-title">{free_gift_subtitle}</div>
        {(product_page_text?.html || product_page_text?.text) && (
          <div
            className="free-gift__text"
            dangerouslySetInnerHTML={{
              __html: product_page_text?.html || product_page_text?.text,
            }}
          ></div>
        )}
        <div className="free-gift__terms-conditions">{terms_conditions}</div>
      </div>
    );
    //});
  });
};

export default memo(ListFreeGift);
