import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useState, useEffect, memo } from 'react';
import loadable from '@loadable/component';
import Slick from 'react-slick';
import { getImgCropShopify, loaded } from '../../utils/helpers';

const PromotionType = loadable(() => import('./PromotionType'));
const AddToWishlist = loadable(() => import('./AddToWishlist'));
const BrandProduct = loadable(() => import('./BrandProduct'));
const ImageDialog = loadable(() => import('./ImageDialog'));
const Video = loadable(() => import('./Video'));

const ProductImage = (props) => {
  const isPC = loaded && window.innerWidth >= 992;
  let slider_nav, slider;
  const {
    state: { product, variant, changeVariant },
    brand,
    isFreeGift,
    _images,
  } = props;

  const [state, setState] = useState({ slider_nav: null, slider: null });
  const [dialog, setDialog] = React.useState({ open: false, image: '', video: '' });

  const [images, setImages] = React.useState({
    more:
      _images.sm_images.filter((img) => !img.altText || img.altText?.indexOf('swatch_') === -1) ||
      [],
    main:
      _images.images.filter((img) => !img.altText || img.altText?.indexOf('swatch_') === -1) || [],
  });

  useEffect(() => {
    const videos = product?.videos || [];
    let __images = {
      more:
        _images.sm_images.filter((img) => !img.altText || img.altText?.indexOf('swatch_') === -1) ||
        [],
      main:
        _images.images.filter((img) => !img.altText || img.altText?.indexOf('swatch_') === -1) ||
        [],
    };

    if (videos.length) {
      videos.forEach((v) => {
        if (v?.index !== undefined) {
          __images.more.splice(v?.index, 0, v);
          __images.main.splice(v?.index, 0, v);
        }
      });
    }

    setImages(__images);
  }, [_images]);

  const countImage = images.more.length;

  const handleOpenDialog = (data) => {
    setDialog({ open: true, ...data });
  };

  const settings = {
    dots: false,
    prevArrow: false,
    infinite: true,
    slidesToShow: countImage <= 4 ? countImage : 4,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    focusOnSelect: true,
    lazyLoad: false,
  };

  useEffect(() => {
    if (changeVariant && slider && variant.image?.originalSrc) {
      const index = images.more.findIndex((img) =>
        img.src.includes((variant.image?.originalSrc || '-').split('?')[0])
      );
      index >= 0 && slider.slickGoTo(index);
    }
  }, [variant, changeVariant]);

  useEffect(() => {
    setState({ slider, slider_nav });
  }, [slider, slider_nav]);

  return (
    <>
      <div className="main__product-view__image">
        <div className="more-views">
          {isPC && (
            <ul>
              <PromotionType product={product} />
              <Slick ref={(ref) => (slider_nav = ref)} asNavFor={state.slider} {...settings}>
                {images.more.map((v, i) => {
                  if (v?.embedUrl) {
                    return (
                      <li key={i}>
                        <img
                          src={getImgCropShopify(v?.imageUrl, '120x120')}
                          alt={v?.imageAlt || product.title}
                        />
                      </li>
                    );
                  }
                  return (
                    <li key={i}>
                      {v?.gatsbyImageData ? (
                        <GatsbyImage
                          loading={i < 5 ? 'eager' : 'lazy'}
                          image={getImage(v)}
                          alt={v?.altText || product.title}
                        />
                      ) : (
                        <img
                          src={getImgCropShopify(v?.src, '120x120')}
                          alt={v?.altText || product.title}
                        />
                      )}
                    </li>
                  );
                })}
              </Slick>
            </ul>
          )}
        </div>

        <div className="main-view">
          <Slick
            dots={false}
            arrows={false}
            ref={(ref) => (slider = ref)}
            asNavFor={state.slider_nav}
            infinite={true}
            slidesToShow={1}
            slidesToScroll={1}
            centerPadding="5px"
            lazyLoad={false}
          >
            {images.main.map((v, i) => {
              if (v?.embedUrl) {
                return (
                  <div key={i} className="fgc-main-video">
                    <Video video={v} />
                  </div>
                );
              }
              return (
                <div
                  key={i}
                  className="fgc-main-img"
                  onClick={() => handleOpenDialog({ image: v.src })}
                >
                  {v?.gatsbyImageData ? (
                    <GatsbyImage
                      loading={i === 0 ? 'eager' : 'lazy'}
                      image={getImage(v)}
                      alt={v?.altText || product.title}
                    />
                  ) : (
                    <img
                      src={getImgCropShopify(v?.src, '700x700')}
                      alt={v?.altText || product.title}
                    />
                  )}
                </div>
              );
            })}
          </Slick>
        </div>
        {!isFreeGift && <AddToWishlist product={product} variant={variant} />}
        <div className="clearfix"></div>
        {dialog.open && (
          <ImageDialog
            dialog={dialog}
            handleClose={() => setDialog({ open: false, image: '', video: '' })}
            alt={product.title}
          />
        )}
      </div>

      <BrandProduct brand={brand} />

      {/* {videos?.length > 0 && (
        <LazyLoad throttle={500} height={300} scroll={true}>
          <Video videos={videos} />
        </LazyLoad>
      )} */}
    </>
  );
};

export default memo(ProductImage);
